.root {
  background-color: #060e28;
  color: white;

  min-height: 100vh;
  padding: 0 30px;
}

.header {
  padding: 30px 0;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.header img {
  width: 170px;
}

.content-wrapper {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
