.download-plugin-info a {
  color: white;
}

.download-plugin-info a:hover {
  color: #ffffffbb;
}

.home-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

@media only screen and (max-width: 800px) {
  .home-container {
    flex-wrap: wrap;
  }
}

.info {
  max-width: 36rem;
}

.headline {
  font-size: 3rem;
  font-weight: 400;
}

.description {
  font-size: 1.125rem;
}

.bullet-points {
  font-size: 1.125rem;
  line-height: 2.5rem;
  font-weight: 500;
}

.bullet-points ul {
  list-style: none;
}

.bullet-points li {
  padding-left: 1.5rem;
  position: relative;
}

.bullet-points li::before {
  border-bottom: 2px solid #f3f6f9;
  border-right: 2px solid #f3f6f9;
  content: "";
  flex: 0 0 auto;
  height: 10px;
  margin-right: 1.25rem;
  margin-top: 0.65rem;
  position: absolute;
  transform: rotate(45deg);
  width: 6px;
  z-index: 2;
  display: flex;
  top: 1px;
  left: 0;
}

.bullet-points li::after {
  background-color: #57c5a0;
  border-radius: 100%;
  content: "";

  height: 1.2075rem;
  left: -0.45rem;
  position: absolute;
  top: 8px;
  width: 1.2075rem;
  z-index: 1;
}

.form {
  max-width: 36rem;
  margin-bottom: auto;

  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 30px;
  color: #3c495a;
  background-color: #f3f6f9;
}

.form-title {
  font-size: 1.5rem;
}

.or-divider-span {
  top: calc(-1rem - 1px);
  text-align: center;
  width: 40px;
  left: calc(50% - 20px);
  right: calc(50% + 20px);
  line-height: 2rem;
  background-color: #f3f6f9;
}
