.email-form {
  text-align: left;
  font-weight: bold;
  --bs-border-radius: 3px;
}

.email-submit {
  --bs-btn-border-color: #1263ff;
  --bs-btn-bg: #1263ff;
  --bs-btn-disabled-border-color: #1263ff;
  --bs-btn-disabled-bg: #1263ff;
  font-weight: 500;
  width: 100%;
}

.privacy-notice {
  color: #b1b1b4;
  font-size: 0.875rem;
  text-align: center;
}

.required-field-prompt {
  font-size: 0.875rem;
}

.email-form input::placeholder {
  opacity: 0.75;
}
